@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --black: #000000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

body {
  width: 100vw;
  position: relative;
  overflow-x: hidden;
  font-family: sans-serif;
}

.ant-tabs.ant-tabs-top .ant-tabs-nav {
  @apply !mb-0;
}

.ant-select .ant-select-selector {
  min-height: 2.6rem;
}

.ant-select-selection-search-input {
  height: 100% !important;
}

.ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.spin-full-width.ant-spin-nested-loading {
  width: 100% !important;
  min-height: 2.8rem !important;
}

.spin-full-width .ant-spin.ant-spin-spinning {
  width: 100% !important;
  min-height: 2.8rem !important;
}

.ant-input-group-wrapper.ant-input-search input {
  height: 2.5rem !important;
}

.ant-input-group-wrapper.ant-input-search .ant-input-group-addon button {
  height: 2.5rem !important;
}

.ant-btn-primary:hover {
  background-color: rgba(255, 0, 0, 0.5) !important;
}